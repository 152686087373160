import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import { REQUEST_PLAYLIST_SUCCESS } from "../playlists/types";
import {
  REQUEST_SCREEN_SUCCESS,
  ScreenState,
  SET_SCREEN_ACTIVE_ITEM,
  UPDATE_CURRENT_ACTIVE_ITEM_WITH_CHILD,
  UPDATE_SCREEN_DEVICE_INFO_SUCCESS,
  UPDATE_SELF_SCREEN_DEVICE_SUCCESS,
} from "./types";

import { PlayerAction } from "../storeTypes";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import {
  ContentViewChannelItem,
  ContentViewPlaylistItem,
} from "../../types/content";
import { SET_CONFIG } from "../config/types";
import { TimelinePlayback } from "../timelines/types";

/**
 * Data about the current screen itself.
 * Assumed the player is only ever running 1 screen at a time.
 */
const initialState: ScreenState = {
  id: "",
  orgId: "",
  deviceId: "",
  name: "",
  spaceId: "",
  activeContentItem: undefined,
  operatingHours: undefined,
  isMuted: undefined,
  deviceInfo: {},
  status: "PENDING",
  isPreview: false,
  playbackMode: TimelinePlayback.TIMELINE_PLAYBACK_SYNC,
  videoSource: "optimized",
  highResolutionImage: true,
  playerWidth: 0,
  playerHeight: 0,
  playerRelease: "",
  playerTimezone: "",
  timezoneOverride: "",
  deviceModel: "",
  devicePlatform: "",
};

export function screenReducer(
  state = initialState,
  action: PlayerAction
): ScreenState {
  switch (action.type) {
    case SET_SCREEN_ACTIVE_ITEM: {
      const updatedState = {
        ...state,
        activeContentItem: action.payload,
      };
      if (isEqual(updatedState, state)) {
        return state;
      } else {
        return updatedState;
      }
    }
    case REQUEST_PLAYLIST_SUCCESS:
    case REQUEST_CHANNEL_SUCCESS:
      const updatedState = {
        ...state,
        spaceId: action.payload.space?.id || null,
      };
      if (isEqual(updatedState, state)) {
        return state;
      } else {
        return updatedState;
      }
    case REQUEST_SCREEN_SUCCESS: {
      const activeContentItemState = state.activeContentItem;
      const activeContentItemPayload = action.payload.screen.activeContentItem;
      let updatedState = {
        ...state,
        ...action.payload.screen,
      };
      if (
        activeContentItemPayload?.type === "channel" &&
        activeContentItemState?.type === "channel"
      ) {
        if (activeContentItemPayload.id === activeContentItemState.id) {
          updatedState = {
            ...state,
            ...action.payload.screen,
            activeContentItem: {
              ...state.activeContentItem,
            } as ContentViewChannelItem,
          };
        }
      }

      if (
        activeContentItemPayload?.type === "playlist" &&
        activeContentItemState?.type === "playlist"
      ) {
        if (activeContentItemPayload.id === activeContentItemState.id) {
          updatedState = {
            ...state,
            ...action.payload.screen,
            activeContentItem: {
              ...state.activeContentItem,
            } as ContentViewPlaylistItem,
          };
        }
      }

      if (isEqual(updatedState, state)) {
        return state;
      } else {
        return updatedState;
      }
    }
    case UPDATE_SELF_SCREEN_DEVICE_SUCCESS: {
      const updatedState = {
        ...state,
        ...pick(action.payload, [
          "playerRelease",
          "playerHeight",
          "playerWidth",
          "playerTimezone",
        ]),
      };

      if (isEqual(updatedState, state)) {
        return state;
      } else {
        return updatedState;
      }
    }
    case UPDATE_SCREEN_DEVICE_INFO_SUCCESS: {
      const updatedState = {
        ...state,
        deviceInfo: {
          ...state.deviceInfo,
          ...action.payload.deviceInfo,
        },
      };

      if (isEqual(updatedState, state)) {
        return state;
      } else {
        return updatedState;
      }
    }
    case UPDATE_CURRENT_ACTIVE_ITEM_WITH_CHILD: {
      const { zoneId } = action.payload.activeItems;
      if (
        zoneId &&
        state.activeContentItem &&
        (state.activeContentItem?.type === "channel" ||
          state.activeContentItem?.type === "playlist")
      ) {
        // if activeContentItem type is channel or playlist
        let updatedState: ScreenState = state;
        if (zoneId === "root") {
          // root zoneId is mean it's playlist
          updatedState = {
            ...state,
            activeContentItem: {
              ...state.activeContentItem,
              activeItems: [action.payload.activeItems], // so activeItem will always has only one item when it's playlist
            } as ContentViewPlaylistItem,
          };
        } else {
          if (!state.activeContentItem.activeItems) {
            // if it's first attempt to assign activeItem value
            updatedState = {
              ...state,
              activeContentItem: {
                ...state.activeContentItem,
                activeItems: [action.payload.activeItems], // just give activeItem a value
              } as ContentViewChannelItem,
            };
          } else if (
            !state.activeContentItem.activeItems.find(
              (item) => item.zoneId === zoneId
            ) // if new zone is never exist in actveItem value
          ) {
            updatedState = {
              ...state,
              activeContentItem: {
                ...state.activeContentItem,
                activeItems: [
                  ...state.activeContentItem.activeItems,
                  action.payload.activeItems,
                ], // combine an old zone value and new zone value
              } as ContentViewChannelItem,
            };
          } else if (
            state.activeContentItem.activeItems.find(
              (item) => item.zoneId === zoneId
            ) // if old zone value is updating
          ) {
            const activeItems = state.activeContentItem.activeItems.filter(
              (item) => item.zoneId !== zoneId
            );
            updatedState = {
              ...state,
              activeContentItem: {
                ...state.activeContentItem,
                activeItems: [...activeItems, action.payload.activeItems], // replace old zone value with a fresh value
              } as ContentViewChannelItem,
            };
          }
        }
        if (isEqual(updatedState, state)) {
          return state;
        } else {
          return updatedState;
        }
      } else {
        return state;
      }
    }
    case SET_CONFIG: {
      if (action.payload.spaceId) {
        return {
          ...state,
          spaceId: action.payload.spaceId,
        };
      }
      return state;
    }

    default:
      return state;
  }
}
