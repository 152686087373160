/*eslint-disable */
import { GqlZoneItemSizeType } from "../../store/graphqlTypes";
import { ContentItemLeaf } from "../../store/contentLists/types";
import { FilesState } from "../../store/files/types";
import { CurrentScreenQuery, FileFragment } from "../../queries";
import { TimelinePlayback } from "../../store/timelines/types";

export const CHANNEL_ZONE_1_DEFAULT_SIZE_TYPE: GqlZoneItemSizeType = {
  image: "fill",
  video: "fill",
  document: "fill",
};
export const DOCUMENT_DEFAULT_DURATION_ON_CHANNEL = 33000;
export const IMAGE_DEFAULT_DURATION_ON_PLAYLIST = 5000;
export const PLAYLIST_FILE_1_DURATION = 6000;
export const CHANNEL_FILE_1_DURATION = 11066;
export const CHANNEL_FILE_2_DURATION = 15100;
export const CHANNEL_FILE_3_DURATION = 83166;
export const CHANNEL_FILE_4_DURATION = 15100;
export const CHANNEL_FILE_5_DURATION = 25700;
export const CHANNEL_FILE_6_DURATION = 26800;
export const CHANNEL_1_LAYOUT_ID = "80db9beb-0ca3-4884-a8b4-19fa12a144d4";
export const SCREEN_TIMEZONE_OVERRIDE = "Asia/Bangkok";

export const CHANNEL_FILE_1: FileFragment = {
  id: "e2e5c931-975c-49cb-83f4-85bc99fe47f0",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "11190648_1560998804162665_1600881676_n.mp4",
  availableAt: null,
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype: "video/mp4",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/3Aazfcg4T3ypJu77dUet_11190648_1560998804162665_1600881676_n_mp4",
  metadata: {
    key: "3Aazfcg4T3ypJu77dUet_11190648_1560998804162665_1600881676_n_mp4",
    url: "https://cdn.filestackcontent.com/BrMMR6GSvuRchx1qoMEy",
    size: 1746562,
    handle: "BrMMR6GSvuRchx1qoMEy",
    source: "local_file_system",
    status: "Stored",
    filename: "11190648_1560998804162665_1600881676_n.mp4",
    uploadId: "9DysJmaVBMR2r6nU",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name: "11190648_1560998804162665_1600881676_n.mp4",
      size: 1746562,
      type: "video/mp4",
    },
    originalPath: "11190648_1560998804162665_1600881676_n.mp4",
  },
  fileOutputsByFileId: {
    nodes: [
      {
        id: "85e4d6d5-259f-4f81-bc3f-b1577f2ac199",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/e2e5c931-975c-49cb-83f4-85bc99fe47f0.mp4",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/e2e5c931-975c-49cb-83f4-85bc99fe47f0.mp4",
          ],
        },
        metadata: {
          width: 640,
          height: 640,
          duration: CHANNEL_FILE_1_DURATION,
        },
        mimetype: "video/mp4",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/e2e5c931-975c-49cb-83f4-85bc99fe47f0.mp4",
      },
      {
        id: "c05a3a56-4616-4d5b-a039-f464afe881b9",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/e2e5c931-975c-49cb-83f4-85bc99fe47f0_snapshot.jpg",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/e2e5c931-975c-49cb-83f4-85bc99fe47f0_snapshot.jpg",
          ],
        },
        metadata: null,
        mimetype: "image/jpeg",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/e2e5c931-975c-49cb-83f4-85bc99fe47f0_snapshot.jpg",
      },
    ],
  },
};
export const CHANNEL_FILE_2: FileFragment = {
  id: "c75040c2-9458-4258-b954-eedd7b8673ff",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "earth.mp4",
  availableAt: null,
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype: "video/mp4",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/okTL3kiTyymo1LN3AVGq_earth_mp4",
  metadata: {
    key: "okTL3kiTyymo1LN3AVGq_earth_mp4",
    url: "https://cdn.filestackcontent.com/nNaLP6pcQvi8T1xh4rfr",
    size: 3739827,
    handle: "nNaLP6pcQvi8T1xh4rfr",
    source: "local_file_system",
    status: "Stored",
    filename: "earth.mp4",
    uploadId: "X2EnNAKuDb0DYjxm",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name: "earth.mp4",
      size: 3739827,
      type: "video/mp4",
    },
    originalPath: "earth.mp4",
  },
  fileOutputsByFileId: {
    nodes: [
      {
        id: "0016239f-9df2-46a3-b02b-73b02a646794",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/c75040c2-9458-4258-b954-eedd7b8673ff_snapshot.jpg",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/c75040c2-9458-4258-b954-eedd7b8673ff_snapshot.jpg",
          ],
        },
        metadata: null,
        mimetype: "image/jpeg",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/c75040c2-9458-4258-b954-eedd7b8673ff_snapshot.jpg",
      },
      {
        id: "9477b9c6-5c1d-4110-810b-8508c844c4d4",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/c75040c2-9458-4258-b954-eedd7b8673ff_snapshot.jpg",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/c75040c2-9458-4258-b954-eedd7b8673ff_snapshot.jpg",
          ],
        },
        metadata: null,
        mimetype: "image/jpeg",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/c75040c2-9458-4258-b954-eedd7b8673ff_snapshot.jpg",
      },
      {
        id: "a60e538d-24ef-4233-afe9-e14e4b66336f",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/c75040c2-9458-4258-b954-eedd7b8673ff.mp4",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/c75040c2-9458-4258-b954-eedd7b8673ff.mp4",
          ],
        },
        metadata: {
          width: 1280,
          height: 720,
          duration: CHANNEL_FILE_2_DURATION,
        },
        mimetype: "video/mp4",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/c75040c2-9458-4258-b954-eedd7b8673ff.mp4",
      },
      {
        id: "e35a3578-0db6-4ecd-8f2e-85ebe22095f6",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/c75040c2-9458-4258-b954-eedd7b8673ff.mp4",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/c75040c2-9458-4258-b954-eedd7b8673ff.mp4",
          ],
        },
        metadata: {
          width: 1280,
          height: 720,
          duration: CHANNEL_FILE_2_DURATION,
        },
        mimetype: "video/mp4",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/c75040c2-9458-4258-b954-eedd7b8673ff.mp4",
      },
    ],
  },
};
export const CHANNEL_FILE_3: FileFragment = {
  id: "0770d1e3-7e2a-4ff9-b138-62410a8c020c",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name:
    "Entering The Stronghold _ Audio Visual Animation    HD!-0pXYp72dwl0.f136.mp4",
  availableAt: "2020-03-05T12:28:00+00:00",
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype: "video/mp4",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/xJrAOGmwQFOCk6Drhl9n_Entering-The-Stronghold-_-Audio-Visual-Animation----HD!-0pXYp72dwl0_f136_mp4",
  metadata: {
    key:
      "xJrAOGmwQFOCk6Drhl9n_Entering-The-Stronghold-_-Audio-Visual-Animation----HD!-0pXYp72dwl0_f136_mp4",
    url: "https://cdn.filestackcontent.com/4eXbYavARLaNOgMPlQJA",
    size: 21174931,
    handle: "4eXbYavARLaNOgMPlQJA",
    source: "local_file_system",
    status: "Stored",
    filename:
      "Entering The Stronghold _ Audio Visual Animation    HD!-0pXYp72dwl0.f136.mp4",
    uploadId: "Jm625Wf8141RjQdE",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name:
        "Entering The Stronghold _ Audio Visual Animation    HD!-0pXYp72dwl0.f136.mp4",
      size: 21174931,
      type: "video/mp4",
    },
    originalPath:
      "Entering The Stronghold _ Audio Visual Animation    HD!-0pXYp72dwl0.f136.mp4",
    width: 200,
    height: 200,
  },
  fileOutputsByFileId: {
    nodes: [
      {
        id: "187cfdab-7600-4cbf-b51d-8fbc7cc5f554",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0770d1e3-7e2a-4ff9-b138-62410a8c020c.mp4",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0770d1e3-7e2a-4ff9-b138-62410a8c020c.mp4",
          ],
        },
        metadata: {
          width: 1280,
          height: 720,
          duration: CHANNEL_FILE_3_DURATION,
        },
        mimetype: "video/mp4",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0770d1e3-7e2a-4ff9-b138-62410a8c020c.mp4",
      },
      {
        id: "24be7f0c-6f8d-4eb1-bc5c-09c0849663f0",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0770d1e3-7e2a-4ff9-b138-62410a8c020c.mp4",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0770d1e3-7e2a-4ff9-b138-62410a8c020c.mp4",
          ],
        },
        metadata: {
          width: 1280,
          height: 720,
          duration: CHANNEL_FILE_3_DURATION,
        },
        mimetype: "video/mp4",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0770d1e3-7e2a-4ff9-b138-62410a8c020c.mp4",
      },
      {
        id: "275c64d0-3f21-4750-9f9f-539523c4f543",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/0770d1e3-7e2a-4ff9-b138-62410a8c020c_snapshot.jpg",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/0770d1e3-7e2a-4ff9-b138-62410a8c020c_snapshot.jpg",
          ],
        },
        metadata: null,
        mimetype: "image/jpeg",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/0770d1e3-7e2a-4ff9-b138-62410a8c020c_snapshot.jpg",
      },
      {
        id: "3f0a7708-7a5d-477d-8b4c-6880ba919bae",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/0770d1e3-7e2a-4ff9-b138-62410a8c020c_snapshot.jpg",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/0770d1e3-7e2a-4ff9-b138-62410a8c020c_snapshot.jpg",
          ],
        },
        metadata: null,
        mimetype: "image/jpeg",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/0770d1e3-7e2a-4ff9-b138-62410a8c020c_snapshot.jpg",
      },
      {
        id: "44189522-c3a3-46e6-8b65-bf96f42437aa",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0770d1e3-7e2a-4ff9-b138-62410a8c020c.mp4",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0770d1e3-7e2a-4ff9-b138-62410a8c020c.mp4",
          ],
        },
        metadata: {
          width: 1280,
          height: 720,
          duration: CHANNEL_FILE_3_DURATION,
        },
        mimetype: "video/mp4",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0770d1e3-7e2a-4ff9-b138-62410a8c020c.mp4",
      },
    ],
  },
};
export const CHANNEL_FILE_4: FileFragment = {
  id: "0a91fc2b-876d-423e-9bab-e7f0b1184b0c",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "parkour.mp4",
  availableAt: null,
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype: "video/mp4",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/Tl8cyJbbRC2SJrV2KWrf_parkour_mp4",
  metadata: {
    key: "Tl8cyJbbRC2SJrV2KWrf_parkour_mp4",
    url: "https://cdn.filestackcontent.com/YxGSNjvSZuBgIKy6hnAH",
    size: 687123,
    handle: "YxGSNjvSZuBgIKy6hnAH",
    source: "local_file_system",
    status: "Stored",
    filename: "parkour.mp4",
    uploadId: "OS7a1yYmoeCRmXh3",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name: "parkour.mp4",
      size: 687123,
      type: "video/mp4",
    },
    originalPath: "parkour.mp4",
  },
  fileOutputsByFileId: {
    nodes: [
      {
        id: "7d636472-92fc-457d-806b-8206e3d90c8b",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/0a91fc2b-876d-423e-9bab-e7f0b1184b0c_snapshot.jpg",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/0a91fc2b-876d-423e-9bab-e7f0b1184b0c_snapshot.jpg",
          ],
        },
        metadata: null,
        mimetype: "image/jpeg",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/0a91fc2b-876d-423e-9bab-e7f0b1184b0c_snapshot.jpg",
      },
      {
        id: "e1cb021d-2ff7-4ff5-b3a9-94265cee01da",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0a91fc2b-876d-423e-9bab-e7f0b1184b0c.mp4",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0a91fc2b-876d-423e-9bab-e7f0b1184b0c.mp4",
          ],
        },
        metadata: {
          width: 320,
          height: 240,
          duration: CHANNEL_FILE_4_DURATION,
        },
        mimetype: "video/mp4",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0a91fc2b-876d-423e-9bab-e7f0b1184b0c.mp4",
      },
    ],
  },
};
export const CHANNEL_FILE_5: FileFragment = {
  id: "7dea35f3-d530-4b3d-93e3-5cce9b4cee81",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "Spirit Move (BETHEL) Motion Background Loop HD-RPyUdpZlwgs.f136.mp4",
  availableAt: null,
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype: "video/mp4",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/izS5ZF5OSgeRtIKBt00N_Spirit-Move-_BETHEL_-Motion-Background-Loop-HD-RPyUdpZlwgs_f136_mp4",
  metadata: {
    key:
      "izS5ZF5OSgeRtIKBt00N_Spirit-Move-_BETHEL_-Motion-Background-Loop-HD-RPyUdpZlwgs_f136_mp4",
    url: "https://cdn.filestackcontent.com/dc6YIX7RBeU2I82AMzqA",
    size: 1422271,
    handle: "dc6YIX7RBeU2I82AMzqA",
    source: "local_file_system",
    status: "Stored",
    filename:
      "Spirit Move (BETHEL) Motion Background Loop HD-RPyUdpZlwgs.f136.mp4",
    uploadId: "SeI732e6PhXch31y",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name:
        "Spirit Move (BETHEL) Motion Background Loop HD-RPyUdpZlwgs.f136.mp4",
      size: 1422271,
      type: "video/mp4",
    },
    originalPath:
      "Spirit Move (BETHEL) Motion Background Loop HD-RPyUdpZlwgs.f136.mp4",
  },
  fileOutputsByFileId: {
    nodes: [
      {
        id: "0ffc30d3-8e44-41a6-a2b8-89294ad601b7",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/7dea35f3-d530-4b3d-93e3-5cce9b4cee81_snapshot.jpg",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/7dea35f3-d530-4b3d-93e3-5cce9b4cee81_snapshot.jpg",
          ],
        },
        metadata: null,
        mimetype: "image/jpeg",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/7dea35f3-d530-4b3d-93e3-5cce9b4cee81_snapshot.jpg",
      },
      {
        id: "bdeaa9b5-38ac-4b94-a647-f3ff41188e93",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/7dea35f3-d530-4b3d-93e3-5cce9b4cee81.mp4",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/7dea35f3-d530-4b3d-93e3-5cce9b4cee81.mp4",
          ],
        },
        metadata: {
          width: 1280,
          height: 720,
          duration: CHANNEL_FILE_5_DURATION,
        },
        mimetype: "video/mp4",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/7dea35f3-d530-4b3d-93e3-5cce9b4cee81.mp4",
      },
      {
        id: "c9ac3dfc-b163-46ad-a1e9-7d0b717a1a7e",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/7dea35f3-d530-4b3d-93e3-5cce9b4cee81.mp4",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/7dea35f3-d530-4b3d-93e3-5cce9b4cee81.mp4",
          ],
        },
        metadata: {
          width: 1280,
          height: 720,
          duration: CHANNEL_FILE_5_DURATION,
        },
        mimetype: "video/mp4",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/7dea35f3-d530-4b3d-93e3-5cce9b4cee81.mp4",
      },
      {
        id: "d153c9b5-e46f-4357-85fc-dd1f89ee943e",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/7dea35f3-d530-4b3d-93e3-5cce9b4cee81_snapshot.jpg",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/7dea35f3-d530-4b3d-93e3-5cce9b4cee81_snapshot.jpg",
          ],
        },
        metadata: null,
        mimetype: "image/jpeg",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/7dea35f3-d530-4b3d-93e3-5cce9b4cee81_snapshot.jpg",
      },
    ],
  },
};
export const CHANNEL_FILE_6: FileFragment = {
  id: "17783956-ea39-46ca-823c-d2b32099e11d",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "SampleVideo_360x240_2mb.mp4",
  availableAt: null,
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype: "video/mp4",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/aSWcVheIRI1fWrnrncuV_SampleVideo_360x240_2mb_mp4",
  metadata: {
    key: "aSWcVheIRI1fWrnrncuV_SampleVideo_360x240_2mb_mp4",
    url: "https://cdn.filestackcontent.com/sTmownNQR6mxi2jad8GD",
    size: 2100396,
    handle: "sTmownNQR6mxi2jad8GD",
    source: "local_file_system",
    status: "Stored",
    filename: "SampleVideo_360x240_2mb.mp4",
    uploadId: "6nJoM04jiv4Z8cjX",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name: "SampleVideo_360x240_2mb.mp4",
      size: 2100396,
      type: "video/mp4",
    },
    originalPath: "SampleVideo_360x240_2mb.mp4",
  },
  fileOutputsByFileId: {
    nodes: [
      {
        id: "8f8882d9-5389-4e00-9223-c736a56f97c1",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/17783956-ea39-46ca-823c-d2b32099e11d_snapshot.jpg",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/17783956-ea39-46ca-823c-d2b32099e11d_snapshot.jpg",
          ],
        },
        metadata: null,
        mimetype: "image/jpeg",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/snapshots/17783956-ea39-46ca-823c-d2b32099e11d_snapshot.jpg",
      },
      {
        id: "966f2594-f155-4d88-afb9-4f9f3edaa48c",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/17783956-ea39-46ca-823c-d2b32099e11d.mp4",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/17783956-ea39-46ca-823c-d2b32099e11d.mp4",
          ],
        },
        metadata: {
          width: 320,
          height: 240,
          duration: CHANNEL_FILE_6_DURATION,
        },
        mimetype: "video/mp4",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/17783956-ea39-46ca-823c-d2b32099e11d.mp4",
      },
    ],
  },
};
export const CHANNEL_FILE_7: FileFragment = {
  id: "3e6436f6-d899-47b1-aee8-ed5d89c0ea61",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "Звернення в ДМСУ.docx",
  availableAt: null,
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/3OrjfY9JRnaiYjcxGntq_%D0%97%D0%B2%D0%B5%D1%80%D0%BD%D0%B5%D0%BD%D0%BD%D1%8F-%D0%B2-%D0%94%D0%9C%D0%A1%D0%A3_docx",
  metadata: {
    key: "3OrjfY9JRnaiYjcxGntq_Звернення-в-ДМСУ_docx",
    url: "https://cdn.filestackcontent.com/h5ENhzpPQZucdrlz6vX3",
    size: 17171,
    handle: "h5ENhzpPQZucdrlz6vX3",
    source: "local_file_system",
    status: "Stored",
    filename: "Звернення в ДМСУ.docx",
    uploadId: "3ItJXJ0Eq400SvG1",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name: "Звернення в ДМСУ.docx",
      size: 17171,
      type:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    originalPath: "Звернення в ДМСУ.docx",
  },
  fileOutputsByFileId: {
    nodes: [
      {
        id: "d051273b-e2fe-40ee-9e85-1309e8b7acc4",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/3e6436f6-d899-47b1-aee8-ed5d89c0ea61_0.png",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/3e6436f6-d899-47b1-aee8-ed5d89c0ea61_0.png",
          ],
        },
        metadata: null,
        mimetype: "image/png",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/3e6436f6-d899-47b1-aee8-ed5d89c0ea61_0.png",
      },
      {
        id: "d1a69f71-3f81-4f75-b1a5-a9034952a75d",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/3e6436f6-d899-47b1-aee8-ed5d89c0ea61_0.png",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/3e6436f6-d899-47b1-aee8-ed5d89c0ea61_0.png",
          ],
        },
        metadata: null,
        mimetype: "image/png",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/3e6436f6-d899-47b1-aee8-ed5d89c0ea61_0.png",
      },
    ],
  },
};
export const CHANNEL_FILE_8: FileFragment = {
  id: "39c83b21-c68e-46d0-a2bf-01358a77155d",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "Звернення в ДМСУ.pdf",
  availableAt: null,
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype: "application/pdf",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/k368nTQ0OkGTfpKl9sQQ_%D0%97%D0%B2%D0%B5%D1%80%D0%BD%D0%B5%D0%BD%D0%BD%D1%8F-%D0%B2-%D0%94%D0%9C%D0%A1%D0%A3_pdf",
  metadata: {
    key: "k368nTQ0OkGTfpKl9sQQ_Звернення-в-ДМСУ_pdf",
    url: "https://cdn.filestackcontent.com/1WSN5APvReWCa6iF08q9",
    size: 48138,
    handle: "1WSN5APvReWCa6iF08q9",
    source: "local_file_system",
    status: "Stored",
    filename: "Звернення в ДМСУ.pdf",
    uploadId: "WUtPXJ2rY718iNIT",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name: "Звернення в ДМСУ.pdf",
      size: 48138,
      type: "application/pdf",
    },
    originalPath: "Звернення в ДМСУ.pdf",
  },
  fileOutputsByFileId: {
    nodes: [
      {
        id: "01b6a1f4-b4bf-4d78-9fc7-7838d4cbf8c7",
        content: {
          keys: [
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/39c83b21-c68e-46d0-a2bf-01358a77155d_0.png",
          ],
          urls: [
            "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/39c83b21-c68e-46d0-a2bf-01358a77155d_0.png",
          ],
        },
        metadata: null,
        mimetype: "image/png",
        url:
          "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/39c83b21-c68e-46d0-a2bf-01358a77155d_0.png",
      },
    ],
  },
};

export const PLAYLIST_FILE_1: FileFragment = {
  id: "7e04ff0a-2dd9-4883-91a8-c3dba64a4b66",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "cyrus-chew-Dl39g6QhOIM-unsplash.jpg",
  availableAt: null,
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype: "image/jpeg",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/ugjF78KpSAqp1wtnvNMl_cyrus-chew-Dl39g6QhOIM-unsplash_jpg",
  metadata: {
    key: "ugjF78KpSAqp1wtnvNMl_cyrus-chew-Dl39g6QhOIM-unsplash_jpg",
    url: "https://cdn.filestackcontent.com/04aBqe2uR1aPtkOGeACk",
    size: 2411205,
    width: 3024,
    handle: "04aBqe2uR1aPtkOGeACk",
    height: 4032,
    source: "local_file_system",
    status: "Stored",
    filename: "cyrus-chew-Dl39g6QhOIM-unsplash.jpg",
    uploadId: "HqsxQsTRcgHtXeEg",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name: "cyrus-chew-Dl39g6QhOIM-unsplash.jpg",
      size: 2411205,
      type: "image/jpeg",
    },
    originalPath: "cyrus-chew-Dl39g6QhOIM-unsplash.jpg",
  },
  fileOutputsByFileId: { nodes: [] },
};
export const PLAYLIST_FILE_2: FileFragment = {
  id: "16bf5c66-abb6-4476-b84d-7e7695568b55",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "borna-bevanda-VwqecUsYKvs-unsplash.jpg",
  availableAt: null,
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype: "image/jpeg",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/k5lKOR7fQXu8UlBo3509_borna-bevanda-VwqecUsYKvs-unsplash_jpg",
  metadata: {
    key: "k5lKOR7fQXu8UlBo3509_borna-bevanda-VwqecUsYKvs-unsplash_jpg",
    url: "https://cdn.filestackcontent.com/mPPE9JtXSTe2iUuNMbPR",
    size: 3362631,
    width: 3456,
    handle: "mPPE9JtXSTe2iUuNMbPR",
    height: 5184,
    source: "local_file_system",
    status: "Stored",
    filename: "borna-bevanda-VwqecUsYKvs-unsplash.jpg",
    uploadId: "ns1E77lTaoR6hoM2",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name: "borna-bevanda-VwqecUsYKvs-unsplash.jpg",
      size: 3362631,
      type: "image/jpeg",
    },
    originalPath: "borna-bevanda-VwqecUsYKvs-unsplash.jpg",
  },
  fileOutputsByFileId: { nodes: [] },
};
export const PLAYLIST_FILE_3: FileFragment = {
  id: "d46aa4db-696f-4d87-ab60-af9a0d45fd0d",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "alexander-andrews-KfPwby-UisA-unsplash.jpg",
  availableAt: null,
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype: "image/jpeg",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/bZSZI7a4QBOhtc86fZ1x_alexander-andrews-KfPwby-UisA-unsplash_jpg",
  metadata: {
    key: "bZSZI7a4QBOhtc86fZ1x_alexander-andrews-KfPwby-UisA-unsplash_jpg",
    url: "https://cdn.filestackcontent.com/gdQLEpm2RRKxKqct980I",
    size: 6177342,
    width: 4700,
    handle: "gdQLEpm2RRKxKqct980I",
    height: 7042,
    source: "local_file_system",
    status: "Stored",
    filename: "alexander-andrews-KfPwby-UisA-unsplash.jpg",
    uploadId: "LIrbBmV1DZ38o3j7",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name: "alexander-andrews-KfPwby-UisA-unsplash.jpg",
      size: 6177342,
      type: "image/jpeg",
    },
    originalPath: "alexander-andrews-KfPwby-UisA-unsplash.jpg",
  },
  fileOutputsByFileId: { nodes: [] },
};
export const PLAYLIST_FILE_4: FileFragment = {
  id: "73571a8d-0332-4e0a-b8d4-244a881f476a",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "callum-wale-5s0MuxRGf6M-unsplash.jpg",
  availableAt: "2020-03-11T16:03:00+00:00",
  expireAt: "2020-03-24T17:00:00+00:00",
  fileProcessingStatus: "COMPLETED",
  mimetype: "image/jpeg",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/XDvJ69cRRTS5gLuO4lYO_callum-wale-5s0MuxRGf6M-unsplash_jpg",
  metadata: {
    key: "XDvJ69cRRTS5gLuO4lYO_callum-wale-5s0MuxRGf6M-unsplash_jpg",
    url: "https://cdn.filestackcontent.com/0OV9BuRyQRaojB5jw9Qv",
    size: 3304107,
    width: 3456,
    handle: "0OV9BuRyQRaojB5jw9Qv",
    height: 5184,
    source: "local_file_system",
    status: "Stored",
    filename: "callum-wale-5s0MuxRGf6M-unsplash.jpg",
    uploadId: "qfNd1Vu2vVj0r97f",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name: "callum-wale-5s0MuxRGf6M-unsplash.jpg",
      size: 3304107,
      type: "image/jpeg",
    },
    originalPath: "callum-wale-5s0MuxRGf6M-unsplash.jpg",
  },
  fileOutputsByFileId: { nodes: [] },
};
export const PLAYLIST_FILE_5: FileFragment = {
  id: "3c38b887-bdd9-44b5-acda-3e14b38160e9",
  orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
  name: "david-peters-lSXQOeWUb9E-unsplash.jpg",
  availableAt: null,
  expireAt: null,
  fileProcessingStatus: "COMPLETED",
  mimetype: "image/jpeg",
  source:
    "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/VcpcdEVESiysaoEpatQc_david-peters-lSXQOeWUb9E-unsplash_jpg",
  metadata: {
    key: "VcpcdEVESiysaoEpatQc_david-peters-lSXQOeWUb9E-unsplash_jpg",
    url: "https://cdn.filestackcontent.com/lNCvO66jQxOFXUQyegpH",
    size: 4993857,
    width: 3845,
    handle: "lNCvO66jQxOFXUQyegpH",
    height: 5768,
    source: "local_file_system",
    status: "Stored",
    filename: "david-peters-lSXQOeWUb9E-unsplash.jpg",
    uploadId: "Vs0CC1JHDdKU1GLy",
    container: "studio-filestack-staging-eu-west-1",
    originalFile: {
      name: "david-peters-lSXQOeWUb9E-unsplash.jpg",
      size: 4993857,
      type: "image/jpeg",
    },
    originalPath: "david-peters-lSXQOeWUb9E-unsplash.jpg",
  },
  fileOutputsByFileId: { nodes: [] },
};

export const CURRENT_SCREEN_QUERY_DATA: CurrentScreenQuery = {
  currentScreen: {
    id: "7804fe88-6a78-4675-b62c-0f47ba871751",
    orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
    deviceId: "999081e1-49f6-42d4-a78f-946b9b029666",
    name: "test screen",
    env: {},
    content: {
      _ref: {
        id: "74030506-017c-4557-9390-19926a2c3537",
        type: "channel",
      },
    },
    preferences: {},
    spaceBySpaceId: {
      id: "space-id-1",
      name: "super space",
      preferences: {},
    },
    status: "LIVE",
    preview: false,
    playbackMode: TimelinePlayback.TIMELINE_PLAYBACK_SYNC,
    videoSource: "optimized",
    highResolutionImage: true,
    playerTimezone: "Asia/Saigon",
    timezoneOverride: SCREEN_TIMEZONE_OVERRIDE,
    devicePlatform: "browser",
    device: {
      id: "999081e1-49f6-42d4-a78f-946b9b029666",
      screenId: "7804fe88-6a78-4675-b62c-0f47ba871751",
      settings: {
        name: "vic gbook",
        updatedAt: 1584442840158,
        orientation: "landscape",
      },
      connection: "online",
      deviceModel: "Browser",
      connected_at: 1588248902.246566,
      player_width: 1680,
      player_height: 403,
      devicePlatform: "browser",
      releaseVersion: "89f82507d955ec7155e67cbb24d553b680f047b5",
      disconnected_at: 1588248308.779056,
      deviceSystemTimezone: "Asia/Saigon",
      overrideTimezone: SCREEN_TIMEZONE_OVERRIDE,
    },
    sitesByScreenId: { nodes: [] },
    orgByOrgId: {
      id: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      brandChannelId: null,
      themeByDefaultChannelThemeId: {
        id: "d405801f-2f78-425a-bc29-52251849070c",
        name: "Luke Test Theme",
        config: {
          bodyFontUrl: "https://fonts.googleapis.com/css?family=Chewy",
          primaryColor: {
            "100": "#cccccc",
            "200": "#999999",
            "300": "#666666",
            "400": "#333333",
            "500": "#000000",
            "600": "#000000",
            "700": "#000000",
            "800": "#000000",
            "900": "#000000",
          },
          textOnPrimary: {
            "100": "#000000",
            "200": "#000000",
            "300": "#FFFFFF",
            "400": "#FFFFFF",
            "500": "#FFFFFF",
            "600": "#FFFFFF",
            "700": "#FFFFFF",
            "800": "#FFFFFF",
            "900": "#FFFFFF",
          },
          headingFontUrl:
            "https://fonts.googleapis.com/css?family=Crete%20Round",
          secondaryColor: {
            "100": "#fdfad1",
            "200": "#fcf5a4",
            "300": "#faf076",
            "400": "#f9eb49",
            "500": "#f8e71c",
            "600": "#c6b816",
            "700": "#948a10",
            "800": "#635c0b",
            "900": "#312e05",
          },
          textOnSecondary: {
            "100": "#000000",
            "200": "#000000",
            "300": "#000000",
            "400": "#000000",
            "500": "#000000",
            "600": "#000000",
            "700": "#FFFFFF",
            "800": "#FFFFFF",
            "900": "#FFFFFF",
          },
        },
      },
      featureFlagsCache: ["beamer", "canvas"],
      preferences: {
        player: {
          player_log_config: {
            endpoint: "example.com",
          },
        },
      },
    },
    channelsByScreenId: {
      nodes: [
        {
          id: "74030506-017c-4557-9390-19926a2c3537",
          name: "test",
          publishedAt: "2020-05-05T11:11:00Z",
          content: {
            props: {
              default_durations: {
                app: 60000,
                link: 60000,
                image: 10000,
                document: DOCUMENT_DEFAULT_DURATION_ON_CHANNEL,
              },
            },
            zones: {
              zone1: {
                list: [
                  {
                    rules: [
                      {
                        date: [{ end: "2020-06-30", start: "2020-04-30" }],
                        time: [],
                        exclusive: false,
                        day_of_week: {
                          fri: true,
                          mon: false,
                          sat: true,
                          sun: true,
                          thu: true,
                          tue: true,
                          wed: true,
                        },
                      },
                    ],
                    content: {
                      _ref: {
                        id: CHANNEL_FILE_1.id,
                        type: "file",
                      },
                      props: {},
                    },
                    list_id: "fa3b7eec-6eb7-42cb-b73d-30f834447626",
                  },
                  {
                    rules: [
                      {
                        date: [{ end: "2020-06-30", start: "2020-04-30" }],
                        time: [],
                        exclusive: false,
                        day_of_week: {
                          fri: true,
                          mon: true,
                          sat: true,
                          sun: true,
                          thu: true,
                          tue: false,
                          wed: true,
                        },
                      },
                    ],
                    content: {
                      _ref: {
                        id: CHANNEL_FILE_2.id,
                        type: "file",
                      },
                      props: {},
                    },
                    list_id: "fce6374b-f447-4321-ad52-008a8b65ba31",
                  },
                  {
                    rules: [
                      {
                        date: [{ end: "2020-06-30", start: "2020-04-30" }],
                        time: [],
                        exclusive: false,
                        day_of_week: {
                          fri: true,
                          mon: true,
                          sat: true,
                          sun: true,
                          thu: false,
                          tue: true,
                          wed: false,
                        },
                      },
                    ],
                    content: {
                      _ref: {
                        id: CHANNEL_FILE_3.id,
                        type: "file",
                      },
                      props: {},
                    },
                    list_id: "3b181a0d-c404-4da6-91b9-91b0eafb64f0",
                  },
                  {
                    rules: [
                      {
                        date: [{ end: "2020-06-30", start: "2020-04-30" }],
                        time: [],
                        exclusive: false,
                        day_of_week: {
                          fri: false,
                          mon: true,
                          sat: false,
                          sun: true,
                          thu: true,
                          tue: true,
                          wed: true,
                        },
                      },
                    ],
                    content: {
                      _ref: {
                        id: CHANNEL_FILE_4.id,
                        type: "file",
                      },
                      props: {},
                    },
                    list_id: "865e4725-31a3-4f33-8f2b-708bd7098d0a",
                  },
                  {
                    rules: [
                      {
                        date: [],
                        time: [{ end: "18:59", start: "10:00" }],
                        exclusive: false,
                        day_of_week: {
                          fri: true,
                          mon: true,
                          sat: true,
                          sun: true,
                          thu: true,
                          tue: true,
                          wed: true,
                        },
                      },
                    ],
                    content: {
                      _ref: {
                        id: CHANNEL_FILE_5.id,
                        type: "file",
                      },
                      props: {},
                    },
                    list_id: "f18c557d-f0c2-4eef-a5b3-ab429465f731",
                  },
                  {
                    rules: [
                      {
                        date: [],
                        time: [{ end: "18:59", start: "10:00" }],
                        exclusive: false,
                        day_of_week: {
                          fri: true,
                          mon: true,
                          sat: false,
                          sun: false,
                          thu: true,
                          tue: true,
                          wed: true,
                        },
                      },
                    ],
                    content: {
                      _ref: {
                        id: CHANNEL_FILE_6.id,
                        type: "file",
                      },
                      props: {},
                    },
                    list_id: "d0e5caea-24f4-4dec-9115-c26d5a23c0e6",
                  },
                  {
                    rules: [],
                    content: {
                      _ref: {
                        id: CHANNEL_FILE_7.id,
                        type: "file",
                      },
                      props: {},
                    },
                    list_id: "b347a08e-264b-4b67-8fc2-4faf981cbf23",
                  },
                  {
                    rules: [
                      {
                        date: [],
                        time: [{ end: "02:00", start: "01:00" }],
                        exclusive: true,
                        day_of_week: {
                          fri: true,
                          mon: true,
                          sat: true,
                          sun: true,
                          thu: true,
                          tue: true,
                          wed: true,
                        },
                      },
                    ],
                    content: {
                      _ref: {
                        id: CHANNEL_FILE_8.id,
                        type: "file",
                      },
                      props: {},
                    },
                    list_id: "920130f8-b4cd-4bdc-b300-b57fe4bb5870",
                  },
                  {
                    rules: [
                      {
                        date: [],
                        time: [{ end: "22:00", start: "15:00" }],
                        exclusive: false,
                        day_of_week: {
                          fri: true,
                          mon: true,
                          sat: true,
                          sun: true,
                          thu: true,
                          tue: true,
                          wed: true,
                        },
                      },
                    ],
                    content: {
                      _ref: {
                        id: "80f303db-ff1d-4cb1-89ff-2a53d7391dd0",
                        type: "playlist",
                      },
                      props: {},
                    },
                    list_id: "428ce173-0a74-4081-bfbf-d815d4197732",
                  },
                ],
                props: {
                  sizing_type: CHANNEL_ZONE_1_DEFAULT_SIZE_TYPE,
                },
              },
            },
          },
          width: 1920,
          height: 1080,
          layoutByChannel: {
            id: CHANNEL_1_LAYOUT_ID,
            zones: 1,
            config: {
              css:
                "<style>#zone1{position:absolute;width:100%;height:100%;top:0;left:0;z-index:10;}</style>",
              name: "Main",
              width: 1920,
              zones: [{ id: "zone1", name: "Main" }],
              height: 1080,
              is_flexible: true,
              is_scalable: true,
            },
            width: 1920,
            height: 1080,
            isFlexible: true,
            isScalable: true,
          },
          sitesByChannelId: { nodes: [] },
          appInstancesByChannelId: { nodes: [] },
          playlistsByChannelId: {
            nodes: [
              {
                id: "80f303db-ff1d-4cb1-89ff-2a53d7391dd0",
                name: "name 1",
                publishedAt: "2020-05-05T11:11:00Z",
                content: {
                  list: [
                    {
                      content: {
                        _ref: {
                          id: PLAYLIST_FILE_1.id,
                          type: "file",
                        },
                        meta: {},
                        props: { duration: PLAYLIST_FILE_1_DURATION },
                      },
                      list_id: "248b7bb5-3468-4866-865f-7d4f6eda304a",
                    },
                    {
                      content: {
                        _ref: {
                          id: PLAYLIST_FILE_2.id,
                          type: "file",
                        },
                        meta: {},
                        props: { duration: 0 },
                      },
                      list_id: "e6d8a89a-0021-4b53-927d-e903b05b779c",
                    },
                    {
                      content: {
                        _ref: {
                          id: PLAYLIST_FILE_3.id,
                          type: "file",
                        },
                        meta: {},
                        props: { duration: 0 },
                      },
                      list_id: "26525c66-6a79-4a1e-85dd-d29f8572ca25",
                    },
                    {
                      content: {
                        _ref: {
                          id: PLAYLIST_FILE_4.id,
                          type: "file",
                        },
                        meta: {},
                        props: { duration: 0 },
                      },
                      list_id: "3466ad03-f18d-44f9-9047-10c1dc52d2bb",
                    },
                    {
                      content: {
                        _ref: {
                          id: PLAYLIST_FILE_5.id,
                          type: "file",
                        },
                        meta: {},
                        props: { duration: 0 },
                      },
                      list_id: "658c47ff-13b7-4087-9c4a-b6d0d9f143ce",
                    },
                  ],
                  props: {
                    play_mode: "loop",
                    total_items: 5,
                    total_durations: 20000,
                    default_durations: {
                      app: 60000,
                      link: 60000,
                      image: IMAGE_DEFAULT_DURATION_ON_PLAYLIST,
                    },
                  },
                },
                filesByPlaylistId: {
                  nodes: [
                    PLAYLIST_FILE_1,
                    PLAYLIST_FILE_2,
                    PLAYLIST_FILE_3,
                    PLAYLIST_FILE_4,
                    PLAYLIST_FILE_5,
                  ],
                },
                linksByPlaylistId: { nodes: [] },
                sitesByPlaylistId: { nodes: [] },
                appInstancesByPlaylistId: { nodes: [] },
              },
            ],
          },

          filesByChannelId: {
            nodes: [
              CHANNEL_FILE_3,
              CHANNEL_FILE_4,
              {
                id: "16bf5c66-abb6-4476-b84d-7e7695568b55",
                orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
                name: "borna-bevanda-VwqecUsYKvs-unsplash.jpg",
                availableAt: null,
                expireAt: null,
                fileProcessingStatus: "COMPLETED",
                mimetype: "image/jpeg",
                source:
                  "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/k5lKOR7fQXu8UlBo3509_borna-bevanda-VwqecUsYKvs-unsplash_jpg",
                metadata: {
                  key:
                    "k5lKOR7fQXu8UlBo3509_borna-bevanda-VwqecUsYKvs-unsplash_jpg",
                  url: "https://cdn.filestackcontent.com/mPPE9JtXSTe2iUuNMbPR",
                  size: 3362631,
                  width: 3456,
                  handle: "mPPE9JtXSTe2iUuNMbPR",
                  height: 5184,
                  source: "local_file_system",
                  status: "Stored",
                  filename: "borna-bevanda-VwqecUsYKvs-unsplash.jpg",
                  uploadId: "ns1E77lTaoR6hoM2",
                  container: "studio-filestack-staging-eu-west-1",
                  originalFile: {
                    name: "borna-bevanda-VwqecUsYKvs-unsplash.jpg",
                    size: 3362631,
                    type: "image/jpeg",
                  },
                  originalPath: "borna-bevanda-VwqecUsYKvs-unsplash.jpg",
                },
                fileOutputsByFileId: { nodes: [] },
              },
              CHANNEL_FILE_6,
              CHANNEL_FILE_8,
              {
                id: "3c38b887-bdd9-44b5-acda-3e14b38160e9",
                orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
                name: "david-peters-lSXQOeWUb9E-unsplash.jpg",
                availableAt: null,
                expireAt: null,
                fileProcessingStatus: "COMPLETED",
                mimetype: "image/jpeg",
                source:
                  "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/VcpcdEVESiysaoEpatQc_david-peters-lSXQOeWUb9E-unsplash_jpg",
                metadata: {
                  key:
                    "VcpcdEVESiysaoEpatQc_david-peters-lSXQOeWUb9E-unsplash_jpg",
                  url: "https://cdn.filestackcontent.com/lNCvO66jQxOFXUQyegpH",
                  size: 4993857,
                  width: 3845,
                  handle: "lNCvO66jQxOFXUQyegpH",
                  height: 5768,
                  source: "local_file_system",
                  status: "Stored",
                  filename: "david-peters-lSXQOeWUb9E-unsplash.jpg",
                  uploadId: "Vs0CC1JHDdKU1GLy",
                  container: "studio-filestack-staging-eu-west-1",
                  originalFile: {
                    name: "david-peters-lSXQOeWUb9E-unsplash.jpg",
                    size: 4993857,
                    type: "image/jpeg",
                  },
                  originalPath: "david-peters-lSXQOeWUb9E-unsplash.jpg",
                },
                fileOutputsByFileId: { nodes: [] },
              },
              CHANNEL_FILE_7,
              {
                id: "73571a8d-0332-4e0a-b8d4-244a881f476a",
                orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
                name: "callum-wale-5s0MuxRGf6M-unsplash.jpg",
                availableAt: "2020-03-11T16:03:00+00:00",
                expireAt: "2020-03-24T17:00:00+00:00",
                fileProcessingStatus: "COMPLETED",
                mimetype: "image/jpeg",
                source:
                  "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/XDvJ69cRRTS5gLuO4lYO_callum-wale-5s0MuxRGf6M-unsplash_jpg",
                metadata: {
                  key:
                    "XDvJ69cRRTS5gLuO4lYO_callum-wale-5s0MuxRGf6M-unsplash_jpg",
                  url: "https://cdn.filestackcontent.com/0OV9BuRyQRaojB5jw9Qv",
                  size: 3304107,
                  width: 3456,
                  handle: "0OV9BuRyQRaojB5jw9Qv",
                  height: 5184,
                  source: "local_file_system",
                  status: "Stored",
                  filename: "callum-wale-5s0MuxRGf6M-unsplash.jpg",
                  uploadId: "qfNd1Vu2vVj0r97f",
                  container: "studio-filestack-staging-eu-west-1",
                  originalFile: {
                    name: "callum-wale-5s0MuxRGf6M-unsplash.jpg",
                    size: 3304107,
                    type: "image/jpeg",
                  },
                  originalPath: "callum-wale-5s0MuxRGf6M-unsplash.jpg",
                },
                fileOutputsByFileId: { nodes: [] },
              },
              CHANNEL_FILE_5,
              {
                id: "7e04ff0a-2dd9-4883-91a8-c3dba64a4b66",
                orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
                name: "cyrus-chew-Dl39g6QhOIM-unsplash.jpg",
                availableAt: null,
                expireAt: null,
                fileProcessingStatus: "COMPLETED",
                mimetype: "image/jpeg",
                source:
                  "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/ugjF78KpSAqp1wtnvNMl_cyrus-chew-Dl39g6QhOIM-unsplash_jpg",
                metadata: {
                  key:
                    "ugjF78KpSAqp1wtnvNMl_cyrus-chew-Dl39g6QhOIM-unsplash_jpg",
                  url: "https://cdn.filestackcontent.com/04aBqe2uR1aPtkOGeACk",
                  size: 2411205,
                  width: 3024,
                  handle: "04aBqe2uR1aPtkOGeACk",
                  height: 4032,
                  source: "local_file_system",
                  status: "Stored",
                  filename: "cyrus-chew-Dl39g6QhOIM-unsplash.jpg",
                  uploadId: "HqsxQsTRcgHtXeEg",
                  container: "studio-filestack-staging-eu-west-1",
                  originalFile: {
                    name: "cyrus-chew-Dl39g6QhOIM-unsplash.jpg",
                    size: 2411205,
                    type: "image/jpeg",
                  },
                  originalPath: "cyrus-chew-Dl39g6QhOIM-unsplash.jpg",
                },
                fileOutputsByFileId: { nodes: [] },
              },
              CHANNEL_FILE_2,
              {
                id: "d46aa4db-696f-4d87-ab60-af9a0d45fd0d",
                orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
                name: "alexander-andrews-KfPwby-UisA-unsplash.jpg",
                availableAt: null,
                expireAt: null,
                fileProcessingStatus: "COMPLETED",
                mimetype: "image/jpeg",
                source:
                  "https://media.staging.eu.next.sc/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/bZSZI7a4QBOhtc86fZ1x_alexander-andrews-KfPwby-UisA-unsplash_jpg",
                metadata: {
                  key:
                    "bZSZI7a4QBOhtc86fZ1x_alexander-andrews-KfPwby-UisA-unsplash_jpg",
                  url: "https://cdn.filestackcontent.com/gdQLEpm2RRKxKqct980I",
                  size: 6177342,
                  width: 4700,
                  handle: "gdQLEpm2RRKxKqct980I",
                  height: 7042,
                  source: "local_file_system",
                  status: "Stored",
                  filename: "alexander-andrews-KfPwby-UisA-unsplash.jpg",
                  uploadId: "LIrbBmV1DZ38o3j7",
                  container: "studio-filestack-staging-eu-west-1",
                  originalFile: {
                    name: "alexander-andrews-KfPwby-UisA-unsplash.jpg",
                    size: 6177342,
                    type: "image/jpeg",
                  },
                  originalPath: "alexander-andrews-KfPwby-UisA-unsplash.jpg",
                },
                fileOutputsByFileId: { nodes: [] },
              },
              CHANNEL_FILE_1,
            ],
          },
          linksByChannelId: { nodes: [] },
          themeByThemeId: null,
        },
      ],
    },
    appInstancesByScreenId: { nodes: [] },
    filesByScreenId: { nodes: [] },
    linksByScreenId: { nodes: [] },
    playlistsByScreenId: { nodes: [] },
    castByCastId: null,
  },
};

export const contentListItems: ContentItemLeaf[] = [
  {
    durationMs: 11066,
    preloadDurationMs: 7000,
    sizeType: "fill",
    id: "e2e5c931-975c-49cb-83f4-85bc99fe47f0",
    type: "file",
    rules: [
      {
        date: [
          {
            end: "2020-06-30",
            start: "2020-04-30",
          },
        ],
        time: [],
        exclusive: false,
        day_of_week: {
          fri: true,
          mon: false,
          sat: true,
          sun: true,
          thu: true,
          tue: true,
          wed: true,
        },
      },
    ],
    availableAt: null,
    expireAt: null,
    listId: "fa3b7eec-6eb7-42cb-b73d-30f834447626",
  },
  {
    durationMs: 15100,
    preloadDurationMs: 7000,
    sizeType: "fill",
    id: "c75040c2-9458-4258-b954-eedd7b8673ff",
    type: "file",
    rules: [
      {
        date: [
          {
            end: "2020-06-30",
            start: "2020-04-30",
          },
        ],
        time: [],
        exclusive: false,
        day_of_week: {
          fri: true,
          mon: true,
          sat: true,
          sun: true,
          thu: true,
          tue: false,
          wed: true,
        },
      },
    ],
    availableAt: null,
    expireAt: null,
    listId: "fce6374b-f447-4321-ad52-008a8b65ba31",
  },
  {
    durationMs: 83166,
    preloadDurationMs: 7000,
    sizeType: "fill",
    id: "0770d1e3-7e2a-4ff9-b138-62410a8c020c",
    type: "file",
    rules: [
      {
        date: [
          {
            end: "2020-06-30",
            start: "2020-04-30",
          },
        ],
        time: [],
        exclusive: false,
        day_of_week: {
          fri: true,
          mon: true,
          sat: true,
          sun: true,
          thu: false,
          tue: true,
          wed: false,
        },
      },
    ],
    availableAt: "2020-03-05T12:28:00+00:00",
    expireAt: null,
    listId: "3b181a0d-c404-4da6-91b9-91b0eafb64f0",
  },
  {
    durationMs: 15100,
    preloadDurationMs: 7000,
    sizeType: "fill",
    id: "0a91fc2b-876d-423e-9bab-e7f0b1184b0c",
    type: "file",
    rules: [
      {
        date: [
          {
            end: "2020-06-30",
            start: "2020-04-30",
          },
        ],
        time: [],
        exclusive: false,
        day_of_week: {
          fri: false,
          mon: true,
          sat: false,
          sun: true,
          thu: true,
          tue: true,
          wed: true,
        },
      },
    ],
    availableAt: null,
    expireAt: null,
    listId: "865e4725-31a3-4f33-8f2b-708bd7098d0a",
  },
  {
    durationMs: 25700,
    preloadDurationMs: 7000,
    sizeType: "fill",
    id: "7dea35f3-d530-4b3d-93e3-5cce9b4cee81",
    type: "file",
    rules: [
      {
        date: [],
        time: [
          {
            end: "18:59",
            start: "10:00",
          },
        ],
        exclusive: false,
        day_of_week: {
          fri: true,
          mon: true,
          sat: true,
          sun: true,
          thu: true,
          tue: true,
          wed: true,
        },
      },
    ],
    availableAt: null,
    expireAt: null,
    listId: "f18c557d-f0c2-4eef-a5b3-ab429465f731",
  },
  {
    durationMs: 26800,
    preloadDurationMs: 7000,
    sizeType: "fill",
    id: "17783956-ea39-46ca-823c-d2b32099e11d",
    type: "file",
    rules: [
      {
        date: [],
        time: [
          {
            end: "18:59",
            start: "10:00",
          },
        ],
        exclusive: false,
        day_of_week: {
          fri: true,
          mon: true,
          sat: false,
          sun: false,
          thu: true,
          tue: true,
          wed: true,
        },
      },
    ],
    availableAt: null,
    expireAt: null,
    listId: "d0e5caea-24f4-4dec-9115-c26d5a23c0e6",
  },
  {
    durationMs: 5000,
    preloadDurationMs: 7000,
    sizeType: "fill",
    id: "3e6436f6-d899-47b1-aee8-ed5d89c0ea61",
    type: "file",
    rules: [],
    availableAt: null,
    expireAt: null,
    listId: "b347a08e-264b-4b67-8fc2-4faf981cbf23",
  },
  {
    durationMs: 5000,
    preloadDurationMs: 7000,
    sizeType: "fill",
    id: "39c83b21-c68e-46d0-a2bf-01358a77155d",
    type: "file",
    rules: [
      {
        date: [],
        time: [
          {
            end: "02:00",
            start: "01:00",
          },
        ],
        exclusive: true,
        day_of_week: {
          fri: true,
          mon: true,
          sat: true,
          sun: true,
          thu: true,
          tue: true,
          wed: true,
        },
      },
    ],
    availableAt: null,
    expireAt: null,
    listId: "920130f8-b4cd-4bdc-b300-b57fe4bb5870",
  },
  {
    durationMs: 6000,
    preloadDurationMs: 7000,
    id: "7e04ff0a-2dd9-4883-91a8-c3dba64a4b66",
    type: "file",
    rules: [
      {
        date: [],
        time: [
          {
            end: "22:00",
            start: "15:00",
          },
        ],
        exclusive: false,
        day_of_week: {
          fri: true,
          mon: true,
          sat: true,
          sun: true,
          thu: true,
          tue: true,
          wed: true,
        },
      },
    ],
    sizeType: "fit",
    availableAt: null,
    expireAt: null,
    listId: "248b7bb5-3468-4866-865f-7d4f6eda304a",
    parent: {
      id: "80f303db-ff1d-4cb1-89ff-2a53d7391dd0",
      type: "playlist",
      listId: "428ce173-0a74-4081-bfbf-d815d4197732",
    },
  },
  {
    durationMs: 5000,
    sizeType: "fit",
    preloadDurationMs: 7000,
    id: "16bf5c66-abb6-4476-b84d-7e7695568b55",
    type: "file",
    rules: [
      {
        date: [],
        time: [
          {
            end: "22:00",
            start: "15:00",
          },
        ],
        exclusive: false,
        day_of_week: {
          fri: true,
          mon: true,
          sat: true,
          sun: true,
          thu: true,
          tue: true,
          wed: true,
        },
      },
    ],
    availableAt: null,
    expireAt: null,
    listId: "e6d8a89a-0021-4b53-927d-e903b05b779c",
    parent: {
      id: "80f303db-ff1d-4cb1-89ff-2a53d7391dd0",
      type: "playlist",
      listId: "428ce173-0a74-4081-bfbf-d815d4197732",
    },
  },
  {
    durationMs: 5000,
    sizeType: "fit",
    preloadDurationMs: 7000,
    id: "d46aa4db-696f-4d87-ab60-af9a0d45fd0d",
    type: "file",
    rules: [
      {
        date: [],
        time: [
          {
            end: "22:00",
            start: "15:00",
          },
        ],
        exclusive: false,
        day_of_week: {
          fri: true,
          mon: true,
          sat: true,
          sun: true,
          thu: true,
          tue: true,
          wed: true,
        },
      },
    ],
    availableAt: null,
    expireAt: null,
    listId: "26525c66-6a79-4a1e-85dd-d29f8572ca25",
    parent: {
      id: "80f303db-ff1d-4cb1-89ff-2a53d7391dd0",
      type: "playlist",
      listId: "428ce173-0a74-4081-bfbf-d815d4197732",
    },
  },
  {
    durationMs: 5000,
    sizeType: "fit",
    preloadDurationMs: 7000,
    id: "73571a8d-0332-4e0a-b8d4-244a881f476a",
    type: "file",
    rules: [
      {
        date: [],
        time: [
          {
            end: "22:00",
            start: "15:00",
          },
        ],
        exclusive: false,
        day_of_week: {
          fri: true,
          mon: true,
          sat: true,
          sun: true,
          thu: true,
          tue: true,
          wed: true,
        },
      },
    ],
    availableAt: "2020-03-11T16:03:00+00:00",
    expireAt: "2020-03-24T17:00:00+00:00",
    listId: "3466ad03-f18d-44f9-9047-10c1dc52d2bb",
    parent: {
      id: "80f303db-ff1d-4cb1-89ff-2a53d7391dd0",
      type: "playlist",
      listId: "428ce173-0a74-4081-bfbf-d815d4197732",
    },
  },
  {
    durationMs: 5000,
    sizeType: "fit",
    preloadDurationMs: 7000,
    id: "3c38b887-bdd9-44b5-acda-3e14b38160e9",
    type: "file",
    rules: [
      {
        date: [],
        time: [
          {
            end: "22:00",
            start: "15:00",
          },
        ],
        exclusive: false,
        day_of_week: {
          fri: true,
          mon: true,
          sat: true,
          sun: true,
          thu: true,
          tue: true,
          wed: true,
        },
      },
    ],
    availableAt: null,
    expireAt: null,
    listId: "658c47ff-13b7-4087-9c4a-b6d0d9f143ce",
    parent: {
      id: "80f303db-ff1d-4cb1-89ff-2a53d7391dd0",
      type: "playlist",
      listId: "428ce173-0a74-4081-bfbf-d815d4197732",
    },
  },
];

export const filesState: FilesState = {
  byId: {
    "0770d1e3-7e2a-4ff9-b138-62410a8c020c": {
      availableAt: "2020-03-05T12:28:00+00:00",
      expireAt: null,
      height: 200,
      id: "0770d1e3-7e2a-4ff9-b138-62410a8c020c",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      mimetype: "video/mp4",
      name:
        "Entering The Stronghold _ Audio Visual Animation    HD!-0pXYp72dwl0.f136.mp4",
      size: 21174931,
      type: "video",
      width: 200,
      urlKey:
        "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0770d1e3-7e2a-4ff9-b138-62410a8c020c.mp4",
      source: "http://media.com/fake/original/key",
    },
    "0a91fc2b-876d-423e-9bab-e7f0b1184b0c": {
      availableAt: null,
      expireAt: null,
      id: "0a91fc2b-876d-423e-9bab-e7f0b1184b0c",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      mimetype: "video/mp4",
      name: "parkour.mp4",
      size: 687123,
      type: "video",
      height: 111,
      width: 111,
      urlKey:
        "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/0a91fc2b-876d-423e-9bab-e7f0b1184b0c.mp4",
      source: "http://media.com/fake/original/key",
    },
    "16bf5c66-abb6-4476-b84d-7e7695568b55": {
      availableAt: null,
      expireAt: null,
      height: 5184,
      id: "16bf5c66-abb6-4476-b84d-7e7695568b55",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      name: "borna-bevanda-VwqecUsYKvs-unsplash.jpg",
      size: 3362631,
      type: "image",
      width: 3456,
      urlKey:
        "/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/k5lKOR7fQXu8UlBo3509_borna-bevanda-VwqecUsYKvs-unsplash_jpg",
      mimetype: "image/jpeg",
      source: "http://media.com/fake/original/key",
    },
    "17783956-ea39-46ca-823c-d2b32099e11d": {
      availableAt: null,
      expireAt: null,
      id: "17783956-ea39-46ca-823c-d2b32099e11d",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      mimetype: "video/mp4",
      name: "SampleVideo_360x240_2mb.mp4",
      size: 2100396,
      type: "video",
      height: 111,
      width: 111,
      urlKey:
        "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/17783956-ea39-46ca-823c-d2b32099e11d.mp4",
      source: "http://media.com/fake/original/key",
    },
    "39c83b21-c68e-46d0-a2bf-01358a77155d": {
      availableAt: null,
      expireAt: null,
      id: "39c83b21-c68e-46d0-a2bf-01358a77155d",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      name: "Звернення в ДМСУ.pdf",
      size: 48138,
      height: 111,
      width: 111,
      type: "document",
      source: "http://media.com/fake/original/key",
      images: [
        {
          availableAt: null,
          expireAt: null,
          id: "39c83b21-c68e-46d0-a2bf-01358a77155d",
          orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
          name: "Звернення в ДМСУ.pdf",
          size: 48138,
          height: 111,
          width: 111,
          type: "image",
          urlKey:
            "/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/39c83b21-c68e-46d0-a2bf-01358a77155d_0.png",
          mimetype: "image/png",
          source: "http://media.com/fake/original/key",
        },
      ],
    },
    "3c38b887-bdd9-44b5-acda-3e14b38160e9": {
      availableAt: null,
      expireAt: null,
      height: 5768,
      id: "3c38b887-bdd9-44b5-acda-3e14b38160e9",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      name: "david-peters-lSXQOeWUb9E-unsplash.jpg",
      size: 4993857,
      type: "image",
      width: 3845,
      urlKey:
        "/0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/VcpcdEVESiysaoEpatQc_david-peters-lSXQOeWUb9E-unsplash_jpg",
      mimetype: "image/jpeg",
      source: "http://media.com/fake/original/key",
    },
    "3e6436f6-d899-47b1-aee8-ed5d89c0ea61": {
      availableAt: null,
      expireAt: null,
      id: "3e6436f6-d899-47b1-aee8-ed5d89c0ea61",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      name: "Звернення в ДМСУ.docx",
      size: 17171,
      height: 111,
      width: 111,
      type: "document",
      source: "http://media.com/fake/original/key",
      images: [
        {
          availableAt: null,
          expireAt: null,
          id: "3e6436f6-d899-47b1-aee8-ed5d89c0ea61",
          orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
          name: "Звернення в ДМСУ.docx",
          size: 17171,
          width: 111,
          height: 111,
          type: "image",
          urlKey:
            "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/3e6436f6-d899-47b1-aee8-ed5d89c0ea61_0.png",
          mimetype: "image/png",
          source: "http://media.com/fake/original/key",
        },
      ],
    },
    "73571a8d-0332-4e0a-b8d4-244a881f476a": {
      availableAt: "2020-03-11T16:03:00+00:00",
      expireAt: "2020-03-24T17:00:00+00:00",
      height: 5184,
      id: "73571a8d-0332-4e0a-b8d4-244a881f476a",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      name: "callum-wale-5s0MuxRGf6M-unsplash.jpg",
      size: 3304107,
      type: "image",
      width: 3456,
      urlKey:
        "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/XDvJ69cRRTS5gLuO4lYO_callum-wale-5s0MuxRGf6M-unsplash_jpg",
      mimetype: "image/jpeg",
      source: "http://media.com/fake/original/key",
    },
    "7dea35f3-d530-4b3d-93e3-5cce9b4cee81": {
      availableAt: null,
      expireAt: null,
      id: "7dea35f3-d530-4b3d-93e3-5cce9b4cee81",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      mimetype: "video/mp4",
      name:
        "Spirit Move (BETHEL) Motion Background Loop HD-RPyUdpZlwgs.f136.mp4",
      size: 1422271,
      type: "video",
      width: 111,
      height: 111,
      urlKey:
        "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/7dea35f3-d530-4b3d-93e3-5cce9b4cee81.mp4",
      source: "http://media.com/fake/original/key",
    },
    "7e04ff0a-2dd9-4883-91a8-c3dba64a4b66": {
      availableAt: null,
      expireAt: null,
      height: 4032,
      id: "7e04ff0a-2dd9-4883-91a8-c3dba64a4b66",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      name: "cyrus-chew-Dl39g6QhOIM-unsplash.jpg",
      size: 2411205,
      type: "image",
      width: 3024,
      urlKey:
        "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/ugjF78KpSAqp1wtnvNMl_cyrus-chew-Dl39g6QhOIM-unsplash_jpg",
      mimetype: "image/jpeg",
      source: "http://media.com/fake/original/key",
    },
    "c75040c2-9458-4258-b954-eedd7b8673ff": {
      availableAt: null,
      expireAt: null,
      id: "c75040c2-9458-4258-b954-eedd7b8673ff",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      mimetype: "video/mp4",
      name: "earth.mp4",
      size: 3739827,
      type: "video",
      width: 111,
      height: 111,
      urlKey:
        "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/c75040c2-9458-4258-b954-eedd7b8673ff.mp4",
      source: "http://media.com/fake/original/key",
    },
    "d46aa4db-696f-4d87-ab60-af9a0d45fd0d": {
      availableAt: null,
      expireAt: null,
      height: 7042,
      id: "d46aa4db-696f-4d87-ab60-af9a0d45fd0d",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      name: "alexander-andrews-KfPwby-UisA-unsplash.jpg",
      size: 6177342,
      type: "image",
      width: 4700,
      urlKey:
        "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/originals/bZSZI7a4QBOhtc86fZ1x_alexander-andrews-KfPwby-UisA-unsplash_jpg",
      mimetype: "image/jpeg",
      source: "http://media.com/fake/original/key",
    },
    "e2e5c931-975c-49cb-83f4-85bc99fe47f0": {
      availableAt: null,
      expireAt: null,
      id: "e2e5c931-975c-49cb-83f4-85bc99fe47f0",
      orgId: "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943",
      mimetype: "video/mp4",
      name: "11190648_1560998804162665_1600881676_n.mp4",
      size: 1746562,
      type: "video",
      width: 111,
      height: 1111,
      urlKey:
        "0d3c647d-2ebc-4cbb-bb3a-d2c4d15ce943/processed/e2e5c931-975c-49cb-83f4-85bc99fe47f0.mp4",
      source: "http://media.com/fake/original/key",
    },
  },
};
