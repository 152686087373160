import {
  FileMediaType,
  PlayerFile,
  VideoStreamingMimetypesPriorityList,
} from "../store/files/types";
import { ConfigState } from "../store/config/types";
import { useContext } from "react";
import ClientApiContext from "../ClientApiContext";
import { SizingType } from "../store/graphqlTypes";
import { ElementSize } from "./helpers";
import { useMemo } from "react";
import { FileProcessingClientInterface } from "../types/filesClient";
import { Maybe } from "../queries";
import { FEATURE_FLAGS_ENUM } from "../featureFlags";

/**
 * This hook must be the only source of truth for media source to be used for loading/rendering files.
 */
export function useMediaSource(
  file: PlayerFile | undefined,
  config: Pick<ConfigState, "mediaProxyEndpoint" | "mediacacheBaseUrl">,
  sizeType: SizingType,
  containerSize: ElementSize,
  featureFlags: Maybe<string>[],
  secureMediaPolicy: string | undefined,
  videoSource: string,
  highResolutionImage: boolean
): string | string[] {
  const fileProcessingClient = useContext(ClientApiContext)
    ?.fileProcessingClient;
  const shouldForceServiceWorkerImages = featureFlags.includes(
    FEATURE_FLAGS_ENUM.FORCE_USE_SERVICEWORKER_IMAGES
  );

  return useMemo(() => {
    if (
      !file ||
      !fileProcessingClient ||
      containerSize.width === 0 ||
      containerSize.height === 0
    ) {
      return "";
    }

    return getFileSource(
      file,
      config,
      sizeType,
      containerSize,
      fileProcessingClient,
      shouldForceServiceWorkerImages,
      secureMediaPolicy,
      videoSource,
      highResolutionImage
    );
  }, [
    file,
    config,
    sizeType,
    containerSize,
    fileProcessingClient,
    shouldForceServiceWorkerImages,
    secureMediaPolicy,
    videoSource,
    highResolutionImage,
  ]);
}

function getFileSource(
  file: PlayerFile,
  config: Pick<
    ConfigState,
    "mediaProxyEndpoint" | "mediacacheBaseUrl" | "device"
  >,
  sizeType: SizingType,
  containerSize: ElementSize,
  fileProcessingClient: FileProcessingClientInterface,
  shouldForceServiceWorkerImages: boolean,
  secureMediaUrlPolicy: string | undefined,
  videoSource: string,
  highResolutionImage: boolean
): string | string[] {
  if (file.type === "document") {
    return file.images.map(
      (imageFile) =>
        getFileSource(
          imageFile,
          config,
          sizeType,
          containerSize,
          fileProcessingClient,
          shouldForceServiceWorkerImages,
          secureMediaUrlPolicy,
          videoSource,
          highResolutionImage
        ) as string
    );
  }

  let mediaUrl: string;
  if (file.type === "image") {
    const {
      widthByDevicePixelRatio,
      heightByDevicePixelRatio,
    } = calculateDevicePixelRatioDimensions(
      containerSize,
      config?.device?.model,
      highResolutionImage
    );

    const imageOptions = {
      sizingType: sizeType,
      size: {
        w: Math.ceil(widthByDevicePixelRatio),
        h: Math.ceil(heightByDevicePixelRatio),
      },
      imageFormat: getImageFormat(file.mimetype.split("/")[1]),
    };
    mediaUrl = fileProcessingClient.getImgixUrl(
      file.urlKey,
      imageOptions,
      secureMediaUrlPolicy
    );
  } else {
    // substring on file.source to get only path not include the first `/`
    const videoSourceURL =
      videoSource === "original" && file.source
        ? new URL(file.source).pathname.substring(1)
        : file.urlKey;
    mediaUrl = fileProcessingClient.getMediaUrlForKey(
      videoSourceURL,
      secureMediaUrlPolicy
    );
  }
  const isStreamingVideo =
    file.type === "video" &&
    VideoStreamingMimetypesPriorityList.includes(file.mimetype);

  return mediaUrlResolver(
    mediaUrl,
    file.type,
    config,
    shouldForceServiceWorkerImages,
    isStreamingVideo
  );
}

function mediaUrlResolver(
  mediaUrl: string,
  fileType: FileMediaType,
  config: Pick<ConfigState, "mediaProxyEndpoint" | "mediacacheBaseUrl">,
  shouldForceServiceWorkerImages: boolean,
  isStreamingMedia = false
): string {
  if (shouldForceServiceWorkerImages) {
    // if this key feature enable return media Url this will be use serviceWorker by default
    return mediaUrl;
  } else if (config.mediaProxyEndpoint) {
    const {
      baseUrl,
      cacheImages,
      cacheVideos,
      cacheStreamingMedia,
    } = config.mediaProxyEndpoint;
    // Return the original url if media proxy is not supposed to cache them
    if (fileType === "image" && !cacheImages) return mediaUrl;
    if (fileType === "video" && isStreamingMedia && !cacheStreamingMedia)
      return mediaUrl;
    if (fileType === "video" && !isStreamingMedia && !cacheVideos)
      return mediaUrl;

    // Caching reverse proxy for the new SCOS/WebOS/Brightsign apps
    const { origin, pathname, search } = new URL(mediaUrl);
    const proxyBase = baseUrl.endsWith("/") ? baseUrl : baseUrl + "/";

    // Url format: http://proxy-host/proxy-path/encoded-target-hostname/target-path
    // The proxy server shall decode target-hostname and forward request to <target-hostname/target-path>
    const newUrl = new URL(
      `${encodeURIComponent(origin)}${pathname}`,
      proxyBase
    );
    newUrl.search = search; // preserve search params
    return newUrl.href;
  } else if (config.mediacacheBaseUrl) {
    // legacy mediacache passed from js player which in turn gets this from chromeos or native apps
    if (!isStreamingMedia) {
      // legacy mediacache cannot handle caching streaming content since video segment urls are not resolved correctly
      const baseUrlParsed = new URL(config.mediacacheBaseUrl);
      baseUrlParsed.searchParams.append("url", mediaUrl);
      return baseUrlParsed.href;
    }
  }

  // fallback return media url if not get in any case above
  return mediaUrl;
}

const getImageFormat = (
  fileFormat: string | undefined
): "png" | "jpg" | undefined => {
  switch (fileFormat) {
    case "png":
      return "png";
    case "jpg":
      return "jpg";
    default:
      return undefined;
  }
};

function calculateDevicePixelRatioDimensions(
  containerSize: ElementSize,
  deviceModel: string | undefined,
  highResolutionImage: boolean
): { widthByDevicePixelRatio: number; heightByDevicePixelRatio: number } {
  const isScreenPreview =
    deviceModel === "screen-preview" || deviceModel === "channel-preview";
  const isHighResolutionImageEnabled =
    !isScreenPreview && window.devicePixelRatio > 1 && highResolutionImage;

  const isPortrait = containerSize.height > containerSize.width;

  const maxWidth = isPortrait ? 1080 : 1920;
  const maxHeight = isPortrait ? 1920 : 1080;

  const widthByDevicePixelRatio = calculateDimension(
    containerSize.width,
    maxWidth,
    isScreenPreview,
    isHighResolutionImageEnabled
  );

  const heightByDevicePixelRatio = calculateDimension(
    containerSize.height,
    maxHeight,
    isScreenPreview,
    isHighResolutionImageEnabled
  );

  return { widthByDevicePixelRatio, heightByDevicePixelRatio };
}

function calculateDimension(
  containerDimension: number,
  maxDimension: number,
  isScreenPreview: boolean,
  isHighResolutionImageEnabled: boolean
): number {
  return isScreenPreview
    ? Math.min(containerDimension, maxDimension)
    : isHighResolutionImageEnabled
    ? containerDimension * window.devicePixelRatio
    : containerDimension;
}
